import React, { useState, useRef, ChangeEvent, ClipboardEvent, useEffect } from "react";

import { codeInvalidHint } from "../invalidInputHints";
import { INPUT_INVALID_STATUS, TInputInValidStatus } from "@/components/CustomInput";

import "./styles.css";
import Log from "@/utils/Log";
import LoadingMask from "@/components/LoadingMask";

const CODE_REGEX = /^[a-zA-Z0-9]+$/;

const SubmitCode = ({
  codeLength = 6,
  invalidStatus,
  isLoading,
  onSubmit,
}: {
  codeLength?: number;
  invalidStatus: TInputInValidStatus;
  isLoading?: boolean;
  onSubmit: (code: string) => void;
}) => {
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
  const [codeElements, setCodeElements] = useState<string[]>(Array(codeLength).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const checkAndSubmitCode = (codeElements: string[]) => {
    const inputCodes = codeElements.reduce((prev, cur) => prev + cur, "");
    Log.debug("inputCodes: ", codeLength, inputCodes);
    if (inputCodes.length === codeLength) {
      onSubmit(inputCodes);
    }
  };

  const handleInputChange = (index: number, value: string) => {
    if (!codeElements[index]) {
      const validValue = CODE_REGEX.test(value[0]) ? value[0] : "";
      const newCodeElements = [...codeElements];
      newCodeElements[index] = validValue?.toUpperCase() || "";
      setCodeElements(newCodeElements);
      if (!validValue) return;
      if (index < codeLength - 1) {
        inputRefs?.current[index + 1].focus();
        setCurrentFocusIndex(index + 1);
      }
      checkAndSubmitCode(newCodeElements);
    } else {
      const newCodeElements = [...codeElements];
      newCodeElements[index] = "";
      setCodeElements(newCodeElements);
    }
  };

  const handleDelete = (index: number) => {
    const newCodeElements = [...codeElements];
    if (index > 0) {
      newCodeElements[index - 1] = "";
      setCodeElements(newCodeElements);
      inputRefs.current[index - 1].focus();
    } else if (index === 0) {
      newCodeElements[index] = "";
      setCodeElements(newCodeElements);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && index > 0 && codeElements[index] === "") {
      event.preventDefault();
      handleDelete(index);
    } else if (event.key === "ArrowLeft" && index > 0) {
      event.preventDefault();
      inputRefs.current[index - 1].focus();
    } else if (event.key === "ArrowRight" && index < codeLength - 1) {
      event.preventDefault();
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event?.clipboardData?.getData("text/plain") || "";
    const pastedCode = pastedData?.replace(/[^a-zA-Z0-9]/g, "").split("");

    let pastedIndex = 0;
    const newCodeElements = codeElements.map((val, index) => {
      if (index >= currentFocusIndex && pastedIndex < pastedCode.length) {
        const newVal = pastedCode[pastedIndex].toUpperCase();
        pastedIndex++;
        return newVal;
      }
      return val;
    });
    checkAndSubmitCode(newCodeElements);
    setCodeElements(newCodeElements);

    let nextFocusIndex = currentFocusIndex + pastedIndex;
    if (nextFocusIndex >= codeLength) {
      nextFocusIndex = codeLength - 1;
    }
    setCurrentFocusIndex(nextFocusIndex);
    inputRefs?.current[nextFocusIndex]?.focus();
  };

  const handleFocus = (index: number) => {
    const input = inputRefs?.current[index];
    setCurrentFocusIndex(index);
    if (input && codeElements[index] !== "") {
      const maxLength = input.maxLength;
      input.setSelectionRange(maxLength, maxLength);
    }
  };

  useEffect(() => {
    inputRefs?.current[0]?.focus();
  }, []);

  return (
    <>
      <LoadingMask isLoading={isLoading} />
      <div
        className={`submit-code-wrapper${invalidStatus !== INPUT_INVALID_STATUS.INITIAL ? " is-invalid" : ""}`}
      >
        {codeElements.map((value, index) => (
          <input
            key={index}
            type="text"
            pattern="[a-zA-Z0-9]{1}"
            maxLength={1}
            value={value}
            onInput={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
            onFocus={() => handleFocus(index)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyDown(index, e);
            }}
            onPaste={handlePaste}
            ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
          />
        ))}
      </div>
      {isLoading ? (
        <div className="submit-code-loading">
          <span className="spinner" />
          <span className="hint">Verifying code</span>
        </div>
      ) : (
        <div className="submit-code-invalid-hint">{codeInvalidHint(invalidStatus)}</div>
      )}
    </>
  );
};

export default SubmitCode;
