import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { ITeamInfoObj } from "@/api/interface";
import { JoinItem } from "../SelectionItem";
import CloseBtn from "./CloseBtn";
import { useAuth } from "@/hooks";
import LoadingButton from "@/components/LoadingButton";
import LoadingMask from "@/components/LoadingMask";

const JoinWorkspacePopup = ({ teamInvitations }: { teamInvitations: ITeamInfoObj[] }) => {
  const auth = useAuth();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set<string>());
  const [isLoading, setLoading] = useState(false);

  const handleJoinSelectionClicked = (teamId: string) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set<string>(prevSelected);
      if (newSelected.has(teamId)) {
        newSelected.delete(teamId);
      } else {
        newSelected.add(teamId);
      }
      return newSelected;
    });
  };

  useEffect(() => {
    if (isPopupVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPopupVisible]);

  const togglePopup = () => {
    setSelectedItems(new Set<string>());
    setPopupVisibility(!isPopupVisible);
  };

  const handleJoinSubmitBtnClicked = useCallback(() => {
    setLoading(true);
    auth.joinWorkspacesByTeamIds([...selectedItems]).finally(() => {
      setLoading(false);
      setPopupVisibility(false);
    });
  }, [selectedItems]);

  return teamInvitations.length ? (
    <>
      <LoadingMask isLoading={isLoading} />
      <div className="popup-trigger-wrapper">
        <span className="content">{`You are also invited to ${teamInvitations.length} other workspace${teamInvitations.length > 1 ? "s" : ""}.`}</span>
        <button className="text-button trigger-btn" onClick={togglePopup}>
          View
        </button>
      </div>
      {isPopupVisible && (
        <div className="popup-content-backdrop" onClick={togglePopup}>
          <div className="popup-content-wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header">
              <span className="title">Join workspace</span>
              <button className="close-btn text-button" onClick={togglePopup}>
                <CloseBtn />
              </button>
            </div>
            <div className="popup-content">
              <div className="selection-wrapper">
                {teamInvitations.map((team, index, arr) => (
                  <JoinItem
                    key={team.teamId}
                    disabledChecked={isLoading}
                    selected={selectedItems.has(team.teamId)}
                    className={index === arr.length - 1 ? "last-item" : ""}
                    onSelectionClicked={() => handleJoinSelectionClicked(team.teamId)}
                    {...team}
                  />
                ))}
              </div>
            </div>
            <div className="popup-footer">
              <button className="cancel-btn" onClick={togglePopup}>
                Cancel
              </button>
              <LoadingButton
                className={`button-primary-360 join-btn${!selectedItems.size ? " disabled" : ""}`}
                isLoading={isLoading}
                onClick={handleJoinSubmitBtnClicked}
                disabled={!selectedItems.size}
              >
                Join
              </LoadingButton>
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default JoinWorkspacePopup;
