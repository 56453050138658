/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, RawAxiosRequestHeaders, AxiosHeaders } from "axios";

import Log from "@/utils/Log";

import serverConfig from "./config";
import {
  handleAuthError,
  handleConfigureAuth,
  handleGeneralError,
  handleNetworkError,
} from "./utils";
import { IAnyObj } from "./interface";
axios.interceptors.request.use(
  (config) => {
    config = { ...serverConfig, ...config };
    config = handleConfigureAuth(config);
    return config;
  },
  (err) => {
    Log.error("REQUEST ERROR", err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error: AxiosError) => {
    const { response } = error;
    Log.debug("server interceptors response: ", response);
    if (response?.status) {
      handleAuthError(response.status);
      handleGeneralError(response.status);
      handleNetworkError(response.status);
    }
    return Promise.reject(error);
  }
);

export const Get = <T>(
  url: string,
  params: IAnyObj = {},
  headers?: RawAxiosRequestHeaders | AxiosHeaders
): Promise<T | undefined> => {
  return new Promise((resolve, rejects) => {
    axios
      .get(url, { params, headers })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err: any) => {
        rejects(err);
      });
  });
};

export const Post = <T>(
  url: string,
  data: IAnyObj | FormData,
  params: IAnyObj = {},
  headers?: RawAxiosRequestHeaders | AxiosHeaders
): Promise<T | undefined> => {
  return new Promise((resolve, rejects) => {
    axios
      .post(url, data, { params, headers })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err: any) => {
        rejects(err);
      });
  });
};

export const Put = <T>(
  url: string,
  data: IAnyObj,
  params: IAnyObj = {}
): Promise<T | undefined> => {
  return new Promise((resolve, rejects) => {
    axios
      .put(url, data, { params })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err: any) => {
        rejects(err);
      });
  });
};

export const Delete = <T>(url: string, params: IAnyObj = {}): Promise<T | undefined> => {
  return new Promise((resolve, rejects) => {
    axios
      .delete(url, { params })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err: any) => {
        rejects(err);
      });
  });
};
