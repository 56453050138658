import React, { useRef, useState, useEffect } from "react";

import CustomInput, { InvalidHint } from "../CustomInput";
import Log from "@/utils/Log";
import { passwordInvalidHint, passwordCheckInvalidHint } from "./invalidInputHints";
import LoadingButton from "../LoadingButton";

const DoubleCheckPassword = ({
  email,
  isLoading,
  onPasswordChecked,
}: {
  email: string;
  isLoading: boolean;
  onPasswordChecked: (newPassword: string) => void;
}) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [pswHint, setPswHint] = useState<React.ReactNode>(null);
  const [pswInvalidHints, setPswInvalidHints] = useState<React.ReactNode>(null);

  const passwordInputRefCheck = useRef<HTMLInputElement>(null);
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [pswCheckInvalidHints, setPswCheckInvalidHints] = useState<React.ReactNode>(null);

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value.trim() || "";
    setPassword(password);
  }

  function handlePasswordFinished() {
    setPswInvalidHints(null);
    passwordInputRefCheck?.current?.focus();
  }

  function handlePasswordInputChangeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const passwordCheck = e?.target?.value.trim() || "";
    setPasswordCheck(passwordCheck);
  }
  function handlePasswordInputFocus() {
    setPswHint(
      <InvalidHint
        content="At least 8 characters with 1 lowercase character, 1 uppercase character, and 1 number"
        className="is-black "
      />
    );
  }

  function handlePasswordFinishedCheck(e?: React.FormEvent) {
    e?.preventDefault();
    const pswCheckRes = passwordInvalidHint(password);
    setPswInvalidHints(pswCheckRes);
    if (pswCheckRes) {
      return;
    }
    const pswDoubleCheckRes = passwordCheckInvalidHint(passwordCheck, password);
    setPswCheckInvalidHints(pswDoubleCheckRes);
    if (!pswDoubleCheckRes) {
      Log.debug("new password is: ", password);
      onPasswordChecked(password);
    }
  }

  useEffect(() => {
    passwordInputRef?.current?.focus();
  }, []);

  return (
    <form>
      <input autoComplete="email" className="is-display-none" type="email" defaultValue={email} />
      <CustomInput
        inputRef={passwordInputRef}
        type="password"
        autoComplete="off"
        value={password}
        onChange={handlePasswordInputChange}
        onFocus={handlePasswordInputFocus}
        onBlur={() => setPswHint(null)}
        onEnter={handlePasswordFinished}
        hintContent={pswInvalidHints ? null : pswHint}
        invalidHintContent={pswInvalidHints}
      />
      <CustomInput
        inputRef={passwordInputRefCheck}
        type="password"
        autoComplete="off"
        placeholder="Password confirm"
        value={passwordCheck}
        onChange={handlePasswordInputChangeCheck}
        onEnter={handlePasswordFinishedCheck}
        invalidHintContent={pswCheckInvalidHints}
      />
      <LoadingButton
        className="button-primary-360"
        isLoading={isLoading}
        onClick={handlePasswordFinishedCheck}
      >
        Continue
      </LoadingButton>
    </form>
  );
};

export default DoubleCheckPassword;
