import React, { useRef, useState, useEffect } from "react";

import CustomInput from "../CustomInput";
import { useAuth } from "@/hooks";
import Log from "@/utils/Log";
import { passwordCheckInvalidHint } from "./invalidInputHints";
import LoadingButton from "../LoadingButton";
import LoadingMask from "../LoadingMask";

const CheckPassword = ({ email }: { email: string; initialFocus?: boolean }) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [isLoading, setLoading] = useState(false);
  const [pswCheckInvalidHints, setPswCheckInvalidHints] = useState<React.ReactNode>(null);

  const auth = useAuth();

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value.trim() || "";
    setPassword(password);
  }

  function handlePasswordFinished(e?: React.FormEvent) {
    e?.preventDefault();
    const pswDoubleCheckRes = passwordCheckInvalidHint(password, password);
    setPswCheckInvalidHints(pswDoubleCheckRes);
    if (!pswDoubleCheckRes) {
      Log.debug("password is: ", password);
      setLoading(true);
      auth.signInWithPassword(email, password).then((isAuthed) => {
        if (!isAuthed) {
          setPswCheckInvalidHints(passwordCheckInvalidHint(password));
          setLoading(false);
        }
      });
    }
  }

  useEffect(() => {
    passwordInputRef?.current?.focus();
  }, []);

  return (
    <>
      <LoadingMask isLoading={isLoading} />
      <form onSubmit={handlePasswordFinished}>
        <input autoComplete="email" className="is-display-none" type="email" defaultValue={email} />
        <CustomInput
          inputRef={passwordInputRef}
          type="password"
          value={password}
          onChange={handlePasswordInputChange}
          onEnter={handlePasswordFinished}
          invalidHintContent={pswCheckInvalidHints}
        />
        <LoadingButton className="button-primary-360" isLoading={isLoading} type="submit">
          Continue
        </LoadingButton>
      </form>
    </>
  );
};

export default CheckPassword;
